

















































import Vue from 'vue'
import jwtDecoded from 'jwt-decode'
export default Vue.extend({
  props: ['title', 'nickName', 'username', 'email', 'roles'],
  name: "TheHeaderDropdownAccnt",
  data() {
    return {
      dataUser: this.$store.state.access_token,
    };
  },
  methods: {
    signOut(): void {
      this.$store.dispatch("ACTIONS_LOGOUT");
    },
    handleChangePassword() {
      this.$router.push('/change_password/update')
    }
  },
  created (): void {
    // if(this.decodeDataUser === '') {
    //   this.$router.push('/auth/signin')
    // }
  },
  computed: {
    decodeDataUser: function(): string | undefined {
      try {
        const data:any = jwtDecoded(this.dataUser)
        const userName:string|undefined = data?.user_name
        return userName?.toUpperCase()
      } catch (err) {
        return '';
      }
    }
  },
})
