var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "custom-container-profile" }, [
    _vm.title
      ? _c("div", { staticClass: "custom-profile-title" }, [
          _vm._v(" " + _vm._s(_vm.title) + " ")
        ])
      : _vm._e(),
    _c("div", { staticClass: "custom-profile-body" }, [
      _c("div", { staticClass: "custom-row" }, [
        _c(
          "div",
          { staticClass: "custom-col grow-1" },
          [
            _c(
              "a-avatar",
              {
                style: { backgroundColor: "#00a2ae", verticalAlign: "middle" },
                attrs: { shape: "square", size: "large" }
              },
              [_vm._v(" " + _vm._s(_vm.nickName ? _vm.nickName : "null") + " ")]
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "custom-col grow-8",
            staticStyle: { alignItems: "normal" }
          },
          [
            _c("div", [
              _c(
                "p",
                {
                  staticClass: "custom-ellipsis",
                  staticStyle: {
                    margin: "0",
                    "font-weight": "bold",
                    "font-size": "20px"
                  }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.username ? _vm.username : "null") + " "
                  )
                ]
              )
            ]),
            _c("div", [
              _c(
                "p",
                {
                  staticClass: "custom-ellipsis",
                  staticStyle: { margin: "0", marginTop: "2px" }
                },
                [_vm._v(" " + _vm._s(_vm.email ? _vm.email : "null") + " ")]
              )
            ]),
            _c(
              "div",
              { staticStyle: { marginTop: "20px" } },
              [
                _vm.roles.length > 0
                  ? _c("a-tag", { attrs: { color: "#fa8c16" } }, [
                      _vm._v(" " + _vm._s(_vm.roles[0]) + " ")
                    ])
                  : _vm._e(),
                _vm.roles.length > 1
                  ? _c("a-tag", { attrs: { color: "#fa541c" } }, [
                      _vm._v(" " + _vm._s(_vm.roles[1]) + " ")
                    ])
                  : _vm._e()
              ],
              1
            )
          ]
        )
      ]),
      _c(
        "div",
        {
          staticClass: "custom-row",
          staticStyle: { justifyContent: "space-between" }
        },
        [
          _c(
            "div",
            {
              staticStyle: { margin: "5px", cursor: "pointer" },
              on: { click: _vm.handleChangePassword }
            },
            [
              _c("a-icon", { staticClass: "mr-2", attrs: { type: "lock" } }),
              _vm._v(_vm._s(_vm.$t("lbl_profile_change_password")) + " ")
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: { margin: "5px", cursor: "pointer" },
              on: { click: _vm.signOut }
            },
            [
              _c("a-icon", { staticClass: "mr-2", attrs: { type: "logout" } }),
              _vm._v(_vm._s(_vm.$t("lbl_profile_signout")) + " ")
            ],
            1
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }